import React, {useEffect, useState} from "react";
import "./style.css";
import Menu from "../menu";
import SubjectsHome from "./subjects";
import Homework from "./homework";
import Task from "./task";
import Lessons from "../lesson/lessons";
import { getSubjectsAndCoursesThuk } from "../../../redux/actions/subjects";
import { activity } from "../../../api/user";
import { useSelector, useDispatch } from "react-redux";
import {useTranslation} from "react-i18next";
import {getHomeworkAssignedByTeacher, getLessonAssignedByTeacher} from "../../../api/lesson";

const Home = () => {

  // to view tab titles in multiple languages
  const { t, i18n } = useTranslation(["home"]);

  // state of tab
  const [tab, setTab] = useState("task");

  // state of homeworks
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [homeworks, setHomeworks] = useState([]);
  const [loading, setLoading] = useState(false);

  // state of tasks

  const [pageTask, setPageTask] = useState(1);
  const [pageSizeTask, setPageSizeTask] = useState(5);
  const [lessons, setLessons] = useState([]);
  const [loadingTask, setLoadingTask] = useState(false);

  const dispatch = useDispatch();
  const { subjects } = useSelector((state) => ({
    subjects: state.subjects,
  }));

  useEffect(() => {
    dispatch(getSubjectsAndCoursesThuk());
    sendActivity();
  }, []);


  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await getHomeworkAssignedByTeacher(page, pageSize);
      const data = res.data.HomeworkModelList ? res.data.HomeworkModelList : [];
      setHomeworks(data);
      setLoading(false);
    })();
  }, []);


  useEffect(() => {
    (async () => {
      setLoadingTask(true);
      const res = await getLessonAssignedByTeacher(pageTask, pageSizeTask);
      const data = res.data.PendingLessonsModelList
        ? res.data.PendingLessonsModelList
        : [];
      setLessons(data);
      setLoadingTask(false);
    })();
  }, []);


  const sendActivity = async () => {
    await activity();
  };

  // change tab function
  function handleChange(e) {
    const value = e.target.value;
    setTab(value);
  }

  return (
    <>
      <Menu />
      <div className="wrapper-content">
        <div className="center-container">
          <div className="container_two-col">
            <SubjectsHome subjects={subjects} />
            <div className="total-wrapper_lessons">
              <Lessons />
            </div>
          </div>
          <div className="container_two-col">
            {/*new tab*/}
            <div className='message-tabs'>
              <input
                id='task'
                type='radio'
                name='tabs'
                value='task'
                onChange={handleChange}
                checked={tab === 'task'}
              />
              <label htmlFor='task'>{t("assignedLessons")} ({lessons.length})</label>
              <input
                id='homework'
                type='radio'
                name='tabs'
                value='homework'
                onChange={handleChange}
                checked={tab === 'homework'}
              />
              <label htmlFor='homework'>{t("homework")} ({homeworks.length})</label>

            </div>

            <div className='message-tabs--content'>
              { tab === 'task' && <Task  lessons={lessons} loading={loadingTask}/>}
            </div>
            <div className='message-tabs--content'>
              { tab === 'homework' && <Homework  homeworks={homeworks} loading={loading} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
