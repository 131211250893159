import React, { useState } from "react";
import "./style.css";
import avatar from "../../../assets/images/sample-avatar.png";
import LoginSuscribe from "../../../assets/images/icon-suscribe.svg";
import LoginInstitucional from "../../../assets/images/icon-institucional.svg";
import LoginTeacher from "../../../assets/images/icon-teacher.svg";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, loginSuccess } from "../../../redux/actions/user";
import { useTranslation } from "react-i18next";
import { TextDangerStyled } from "./style";
import { BASE_URL_SITE_TEACHER, IS_APP_MANAGED_BY_REQUIRED, ROLE_STUDENT_STR } from "../../../config/environment";
import { loginSso } from "../../../api/user";
import { useMsal } from "@azure/msal-react";
import loading from "../../../redux/actions/loading";
import AzureIcon from "../../../assets/images/icon-Microsoft.svg";
import { hasRole } from "../../../utils/utils";

const LoginForm = () => {
  const history = useHistory();
  const { instance } = useMsal();
  const { register, errors, handleSubmit } = useForm();
  const { parameters } = useSelector((state) => ({
    parameters: state.parameters,
  }));

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["login"]);

  const onSubmit = (data) => {
    dispatch(login(data, history));
  };

  const handleLogin = () => {
    instance
      .loginPopup()
      .then(async (response) => {
        console.log("Login success:", response);
        instance.setActiveAccount(response.account);
        const { idToken } = response;
        await loginSsoAzure(idToken);
      })
      .catch((error) => {
        console.log("Login failed:", error);
      });
  };

  const loginSsoAzure = async (idToken) => {
    dispatch(loading(true));
    try {
      const res = await loginSso(idToken);
      const session = res.data;
      dispatch(loading(false));
      if (hasRole(session, [ROLE_STUDENT_STR])) {
        localStorage.setItem("auth", JSON.stringify(session));
        dispatch(loginSuccess(session));
        history.push("/students/home");
      } else {
        postCrossRedirectSiteTeachers(session);
      }
    } catch (error) {
      dispatch(loading(false));
    }
  };

  const postCrossRedirectSiteTeachers = (session) => {
    let iframe = document.createElement('iframe');
    iframe.src = BASE_URL_SITE_TEACHER;
    iframe.loading = 'lazy';
    iframe.style = 'visibility: hidden;';
    document.body.appendChild(iframe);
    iframe.onload = () => {
      let win = iframe.contentWindow;
      win.postMessage(session, BASE_URL_SITE_TEACHER);
      const url = `${BASE_URL_SITE_TEACHER}/#/dashboard`;
      window.location.href = url;
    }
  };

  return (
    <>
      <div className="wrapper-container-login">
        <div className="container-login">
          <div className="col-login-left">
            <div className="module-w-padding">
              <form onSubmit={handleSubmit(onSubmit)}>
                <h1 className="container-login_title"> {t("loginUser")} </h1>
                {parameters.internalLoginManagement && <input
                  type="text"
                  name="username"
                  placeholder={t("user")}
                  ref={register({
                    required: {
                      value: true,
                      message: t("validateUserRequired"),
                    },
                  })}
                />}

                {errors.username && (
                  <TextDangerStyled>{errors.username.message}</TextDangerStyled>
                )}
                {parameters.internalLoginManagement && <input
                  type="password"
                  name="password"
                  placeholder={t("password")}
                  ref={register({
                    required: {
                      value: true,
                      message: t("validatePasswordRequired"),
                    },
                  })}
                />}
                {errors.password && (
                  <TextDangerStyled>{errors.password.message}</TextDangerStyled>
                )}
                {parameters.internalLoginManagement && <button type="submit" className="container-login_btn-login">
                  {" "}
                  {t("loginUser")}{" "}
                </button>}
                {parameters.microsoftSSOManagement && <button type="button" class="btn-Microsoft" onClick={handleLogin}> <img src={AzureIcon} />{t("loginSso")}</button>}
                {parameters.internalPasswordReset && <a
                  className="container-login_txt-secondary"
                  onClick={() => history.push("/password-reset")}
                >
                  {t("forgot")}
                </a>}
              </form>
            </div>
          </div>

          {IS_APP_MANAGED_BY_REQUIRED ? (<div className="col-login-right">
            <div className="module-login--subscribe">
              <img src={LoginSuscribe} alt="" />
              <h1>
                <strong>{t("notAccount")}</strong>
              </h1>
              <a
                href={`${BASE_URL_SITE_TEACHER}/#/select-plan`}
                target="_blank"
              >
                {t("subscribe")}
              </a>
            </div>

            <div className="module-login--teacher">
              <img src={LoginTeacher} alt="" />
              <h1>{t("textRegister")}</h1>
              <a
                href={`${BASE_URL_SITE_TEACHER}/#/teachers`}
                target="_blank"
              >
                {t("register")}
              </a>
              <p className="txt-small">
                {t("grades")} <br />
                {t("matter")}
              </p>
            </div>

            <div className="module-login--institutional">
              <img src={LoginInstitucional} alt="" />
              <h1>
                {t("account")} <strong>{t("institutional")}</strong>
              </h1>
              <p>
                {t("textInstitutional")}
              </p>
            </div>
          </div>) : (
            <div className="col-login-right">
              <div className="module-login--institutional">
                <img src={LoginInstitucional} alt="" />
                <h1>
                  {t("account")} <strong>{t("institutional")}</strong>
                </h1>
                <p>
                  {t("textInstitutional")}
                </p>
              </div>

              <div className="module-login--teacher">
                <img src={LoginTeacher} alt="" />
                <h1>{t("textRegister")}</h1>
                <a
                  href={IS_APP_MANAGED_BY_REQUIRED ? `${BASE_URL_SITE_TEACHER}/#/teachers` : `${BASE_URL_SITE_TEACHER}/#/login`}
                  target="_blank"
                >
                  {t("register")}
                </a>
                <p className="txt-small">
                  {t("grades")} <br />
                  {t("matter")}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LoginForm;
