import { authApi, getSettingsPowerDe } from "../../api/user";
import loading from "./loading";
import { DEFAULT_STATE, LOGIN_SUCCESS, SET_PARAMETERS } from "../../constants/general";
import { GENIAL_WEB_LOGIN_PAGE, BASE_URL_SITE_TEACHER, ROLE_STUDENT_STR } from "../../config/environment";
import { hasRole } from "../../utils/utils";
import store from "../../redux/store";

export const settingsSuccess = (data) => {
  return {
    type: SET_PARAMETERS,
    payload: data,
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logout = () => {
  localStorage.removeItem("auth");
  localStorage.removeItem("subject");
  localStorage.removeItem("GLOBAL_STATE");  
  store.dispatch(getSettings());
  window.location.href = GENIAL_WEB_LOGIN_PAGE;
  return {
    type: DEFAULT_STATE,
  };
};

export const login = (credentials, history) => {
  return async (dispatch, getState) => {
    try {
      dispatch(loading(true));
      const res = await authApi(credentials);
      const user = {
        InvitationCode: res.data.InvitationCode,
        Name: res.data.Name,
        Roles: res.data.Roles,
        Token: res.data.Token,
        Username: res.data.Username,
      };
      if (hasRole(user, [ROLE_STUDENT_STR])) {
        localStorage.setItem("auth", JSON.stringify(user));
        dispatch(loginSuccess(user));
        history.push("/students/home");
      } else {
        postCrossRedirectSiteTeachers(user);
      }
    } catch {
    } finally {
      dispatch(loading(false));
    }
  };
};

export const getSettings = () => {
  return async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await getSettingsPowerDe();
      const data = response.data;
      const params = {
        messageManagementStudent: data.MessageManagementStudent,
        messageManagementSendStudent: data.MessageManagementSendStudent,
        progressManagementStudent: data.ProgressManagementStudent,
        teamsManagementStudent: data.TeamsManagementStudent,
        chatManagementStudent: data.ChatManagementStudent,
        microsoftSSOManagement: data.MicrosoftSSOManagement,
        internalLoginManagement: data.InternalLoginManagement,
        internalPasswordReset: data.InternalPasswordReset
      };
      dispatch(settingsSuccess(params));
      dispatch(loading(false));
    } catch {
      dispatch(loading(false));
    }
  };
};

const postCrossRedirectSiteTeachers = (session) => {
  let iframe = document.createElement('iframe');
  iframe.src = BASE_URL_SITE_TEACHER;
  iframe.loading = 'lazy';
  iframe.style = 'visibility: hidden;';
  document.body.appendChild(iframe);
  iframe.onload = () => {
    let win = iframe.contentWindow;
    win.postMessage(session, BASE_URL_SITE_TEACHER);
    const url = `${BASE_URL_SITE_TEACHER}/#/dashboard`;
    window.location.href = url;
  }
};
