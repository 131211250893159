import React, { useState, useEffect } from "react";
import "./style.css";
import Menu from "../menu";
import Received from "./received";
import Sent from "./sent";
import MessageComponerDialog from "./message-componer-dialog";
import { useDispatch, useSelector } from "react-redux";
import { getMessagesUnreadsThunk } from "../../../redux/actions/unread";
import {
  getMetadataReceivedsThunk,
  getMetadataReceivedsSuccess,
} from "../../../redux/actions/received";
import {
  getMessagesSentThunk,
  getMessagesSentSuccess,
} from "../../../redux/actions/sent";
import { useTranslation } from "react-i18next";

const Message = () => {
  const { t, i18n } = useTranslation(["message"]);
  const [tab, setTab] = useState("received");
  const [open, setOpen] = useState(false);
  const [paginationReceived, setPaginationReceived] = useState({});
  const [paginationSent, setPaginationSent] = useState({});

  const dispatch = useDispatch();
  const { parameters, unread } = useSelector((state) => ({
    parameters: state.parameters,
    unread: state.unread
  }));

  useEffect(() => {
    dispatch(getMessagesUnreadsThunk());
  }, []);

  function handleChange(e) {
    const value = e.target.value;
    setTab(value);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRefresh = () => {
    dispatch(getMessagesUnreadsThunk());
    if (tab === "received") {
      dispatch(getMetadataReceivedsSuccess({}));
      dispatch(
        getMetadataReceivedsThunk(
          paginationReceived.page,
          paginationReceived.pageSize
        )
      );
    } else if (tab === "sent") {
      dispatch(getMessagesSentSuccess({}));
      dispatch(
        getMessagesSentThunk(paginationSent.page, paginationSent.pageSize)
      );
    }
  };

  return (
    <>
      <Menu />
      <div className="wrapper-content">
        <div className="center-container">
          <div className="wrapper-message-tabs content-max-width">
            <div className="message-content-buttons-mail">
              <button
                type="submit"
                className="button-primary"
                onClick={handleRefresh}
              >
                {" "}
                <i className="fas fa-redo-alt"></i>
                {t("refresh")}{" "}
              </button>
              {parameters.messageManagementSendStudent && <button
                type="submit"
                className="button-secondary"
                onClick={handleClickOpen}
              >
                {" "}
                <i className="fas fa-plus"></i>
                {t("componer")}{" "}
              </button>}
            </div>
            <div className="message-tabs">
              <input
                id="received"
                type="radio"
                name="tabs"
                value="received"
                onChange={handleChange}
                checked={tab === "received"}
              />
              <label htmlFor="received">
                <i className="fas fa-mail-bulk"></i>{" "}
                <span className="clear">{t("received")}</span>{" "}
                <span className="number-no-read">
                  ({unread} {t("unread")})
                </span>
              </label>
              <div className="message-tabs--content">
                {tab === "received" && (
                  <Received setPaginationReceived={setPaginationReceived} />
                )}
              </div>

              {parameters.messageManagementSendStudent && (<><input
                id="sent"
                type="radio"
                name="tabs"
                value="sent"
                onChange={handleChange}
                checked={tab === "sent"}
              />
              <label htmlFor="sent">
                <i className="fas fa-paper-plane"></i>{" "}
                <span className="clear">{t("sent")}</span>
              </label>
              <div className="message-tabs--content">
                {tab === "sent" && (
                  <Sent setPaginationSent={setPaginationSent} />
                )}
              </div></>)}
            </div>
            {open && (
              <MessageComponerDialog
                open={open}
                onClose={handleClose}
                handleRefresh={handleRefresh}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Message;
