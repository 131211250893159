import React, { useState, useEffect } from "react";
import "./style.css";
import { getLessonAssignedByTeacher } from "../../../../api/lesson";
import { SkeletonStyled } from "../../../common/styled-components/skeleton";
import IconCongratsStar from "../../../../assets/images/icon-congratulations-homework.png";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { assignColor } from "../../../../utils/lesson";
import { getNameSubject } from "../../../../utils/subject";
import {getTimeZone, isBefore, toUTC} from "../../../../utils/utils";
import moment from "moment";
import "moment-timezone";

const MessageCongratsHomework = styled.div`
  color: #222;
  display: block;
  justify-content: center;
  text-align: center;
  margin: 3em auto;
  font-size: 13px;

  & img {
    display: block;
    margin: 2em auto 0.25em auto;
  }

  & h1 {
    font-size: 30px;
    line-height: 0;
    color: #AB863D;
  }
`;

const Item = ({ lesson }) => {
  const { t, i18n } = useTranslation(["home"]);
  const history = useHistory();

  const { subjects } = useSelector((state) => ({
    subjects: state.subjects,
  }));


  const goTo = (path) => {
    history.push(path);
  }

  // because we need validate if the test is overdue



  return (
    <>
      <div className="content-item-homework">
        <div className="item-homework--homework">
          <div className="homework-description">
            <p className="txt-homework">{lesson.Title}</p>
            <p
              className="matter-homework"
              style={{ color: `${assignColor(lesson.Subject)}` }}
            >
              {getNameSubject(lesson.Subject, subjects)}
            </p>
          </div>
        </div>

        <div className="item-homework--button">
          <button
            type="submit"
            className="button-homework"
            onClick={() => history.push(`/students/lesson/${lesson.LessonId}`)}
          >
            {" "}
            {t("viewLesson")}{" "}
          </button>
        </div>
        {/*  adding div with */}
        <div className="homework-date-div">
          <span className={'date-homework-title'}>
            {t("testsDate")}:
          </span>
          <div className={'homework-div'}>

            {lesson.StudentExamModels
              && lesson.StudentExamModels.length > 0
              && lesson.StudentExamModels.map((exam, i) => (
                exam.Completed === 1 ?
                  <p key={i} className={(isBefore(exam.DueDate) && exam.Completed === 0) ? 'date-over-due cursor-pointer' : ''}>
                    <span className={'homework-date-title-test'}>
                    Test {i + 1}:
                    </span>
                      <span className={'homework-date-test-completed'}>{t("Completed")}</span>
                    {i === lesson.StudentExamModels.length - 1 ? ' ' : ', '}
                  </p>
                :
                <p key={i} className={(isBefore(exam.DueDate) && exam.Completed === 0) ? 'date-over-due cursor-pointer' : ''}>
                    <span className={'homework-date-title-test'}>
                    Test {i + 1}:
                    </span>
                  {exam.Completed === 1 ?
                    <span className={'homework-date-test-completed'}>{t("Completed")}</span>
                    : exam.DueDate ?
                        moment(exam.DueDate, "MM/DD/YYYY HH:mm:ss").utc()
                        .tz(getTimeZone())
                        .format("MM/DD/YYYY hh:mm a")
               : ""}
                  {i === lesson.StudentExamModels.length - 1 ? ' ' : ', '}

                </p>

              ))}

          </div>
        </div>
      </div>
    </>
  );
};

const Task = ({page, pageSize, lessons, loading}) => {
  const { t, i18n } = useTranslation(["home"]);
  const history = useHistory();

  let component;
  if (!lessons.length) {
    component = (
      <MessageCongratsHomework>
        <img src={IconCongratsStar} alt="" /> <h1>{t("congratulations")}</h1>{" "}
        {t("emptyTask")}
      </MessageCongratsHomework>
    );
  } else {
    component = (
      <>
        {lessons.map((lesson, i) => (
          <Item key={i} lesson={lesson} />
        ))}
        {lessons.length >= 5 && (
          <button
            onClick={() => history.push(`/students/lesson?q=assigned`)}
            className="button-see-more"
          >
            {t("showMoreLessons")}
          </button>
        )}
      </>
    );
  }

  return (
    <>
      <div className="wrapper-home-homework">
        {/*<h2>{t("assignedLessons")}</h2>*/}
        {loading ? (
          <SkeletonStyled>
            <div className="area"></div>
            <div className="area"></div>
            <div className="area"></div>
            <div className="area"></div>
            <div className="area"></div>
            <div className="area"></div>
          </SkeletonStyled>
        ) : (
          component
        )}
      </div>
    </>
  );
};

export default Task;
