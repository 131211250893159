import React from "react";
import "./style.css";
import HeaderLogin from "../../login/header";
import FooterLogin from "../../login/footer";
import { useTranslation } from "react-i18next";
import Faq from "react-faq-component";
import { HTTP_USER_MANUAL_STUDENTS } from "../../../config/environment";
import styled from 'styled-components';
import { ESTUDIA_AVANZA_STUDENT_ELEMENTARY, ESTUDIA_AVANZA_STUDENT_INTERMEDIATE, ESTUDIA_AVANZA_STUDENT_SUPERIOR, STUDENT_TUTORIAL_ELEMENTARY_PLATFORM_FUNCTIONS, STUDENT_TUTORIAL_INTERMEDIATE_PLATFORM_FUNCTIONS, STUDENT_TUTORIAL_SUPERIOR_PLATFORM_FUNCTIONS, STUDENT_TUTORIAL_ELEMENTARY_PARTS_OF_LESSONS, STUDENT_TUTORIAL_INTERMEDIATE_PARTS_OF_LESSONS, STUDENT_TUTORIAL_SUPERIOR_PARTS_OF_LESSONS } from "../../../config/environment";

const VideoContainer = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  max-width: 100%;
  margin: 1rem;
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

const FaqStudents = () => {
  const { t } = useTranslation(["faq"]);

  const dataQuestions = {
    title: <div className="faq-title"><i className="fas fa-question"></i> {t("title-student")}</div>,
    rows: [
      {
        title: `${t("student-password-forgot")}`,
        content: `${t("student-password-forgot-exp")}`,
      },
      {
        title: `${t("student-access-tests")}`,
        content: `${t("student-access-tests-exp")}`,
      },
      {
        title: `${t("student-lessons-assignments")}`,
        content: `${t("student-lessons-assignments-exp")}`,
      },
      {
        title: `${t("student-assignments-completed")}`,
        content: `${t("student-assignments-completed-exp")}`,
      },
      {
        title: `${t("student-grades")}`,
        content: `${t("student-grades-exp")}`,
      },
      {
        title: `${t("student-forums")}`,
        content: `${t("student-forums-exp")}`,
      },
      {
        title: `${t("student-forums-2")}`,
        content: `${t("student-forums-2-exp")}`,
      },
      {
        title: `${t("student-chat")}`,
        content: `${t("student-chat-exp")}`,
      },
      {
        title: `${t("student-comments")}`,
        content: `${t("student-comments-exp")}`,
      },
      {
        title: `${t("student-library")}`,
        content: `${t("student-library-exp")}`,
      },
      {
        title: `${t("student-what-is-app")}`,
        content: `${t("student-what-is-app-exp")}`,
      },
      {
        title: `${t("student-notifications")}`,
        content: `${t("student-notifications-exp")}`,
      },
      {
        title: `${t("student-connection-problem")}`,
        content: `${t("student-connection-problem-exp")}`,
      },
      {
        title: `${t("student-forum")}`,
        content: `${t("student-forum-exp")}`,
      },
      {
        title: `${t("student-incident-report")}`,
        content: `${t("student-incident-report-exp")}`,
      },
      {
        title: `${t("student-average")}`,
        content: `${t("student-average-exp")}`,
      },
      {
        title: `${t("student-profile")}`,
        content: `${t("student-profile-exp")}`,
      },
      {
        title: `${t("student-subtitles")}`,
        content: `${t("student-subtitles-exp")}`,
      },
      {
        title: `${t("student-lesson")}`,
        content: `${t("student-lesson-exp")}`,
      },
    ],
  };

  const dataVideos = {
    title: <div className="faq-title"><i className="fas fa-video"></i> {t("videos")}</div>,
    rows: [
      {
        title: `${t("know_your_platform")}`,
        content: <ul>
          <li>{t("elementary")}<VideoContainer>
            <Video src={ESTUDIA_AVANZA_STUDENT_ELEMENTARY} controls />
          </VideoContainer></li>
          <li>{t("intermediate")}<VideoContainer>
            <Video src={ESTUDIA_AVANZA_STUDENT_INTERMEDIATE} controls />
          </VideoContainer></li>
          <li>{t("superior")}<VideoContainer>
            <Video src={ESTUDIA_AVANZA_STUDENT_SUPERIOR} controls />
          </VideoContainer></li>
        </ul>
      },
      {
        title: `${t("platform_features")}`,
        content: <ul>
          <li>{t("elementary")}<VideoContainer>
            <Video src={STUDENT_TUTORIAL_ELEMENTARY_PLATFORM_FUNCTIONS} controls />
          </VideoContainer></li>
          <li>{t("intermediate")}<VideoContainer>
            <Video src={STUDENT_TUTORIAL_INTERMEDIATE_PLATFORM_FUNCTIONS} controls />
          </VideoContainer></li>
          <li>{t("superior")}<VideoContainer>
            <Video src={STUDENT_TUTORIAL_SUPERIOR_PLATFORM_FUNCTIONS} controls />
          </VideoContainer></li>
        </ul>
      },
      {
        title: `${t("parts_of_the_lesson")}`,
        content: <ul>
          <li>{t("elementary")}<VideoContainer>
            <Video src={STUDENT_TUTORIAL_ELEMENTARY_PARTS_OF_LESSONS} controls />
          </VideoContainer></li>
          <li>{t("intermediate")}<VideoContainer>
            <Video src={STUDENT_TUTORIAL_INTERMEDIATE_PARTS_OF_LESSONS} controls />
          </VideoContainer></li>
          <li>{t("superior")}<VideoContainer>
            <Video src={STUDENT_TUTORIAL_SUPERIOR_PARTS_OF_LESSONS} controls />
          </VideoContainer></li>
        </ul>
      },
    ],
  };

  const downloadManual = () => {
    window.open(HTTP_USER_MANUAL_STUDENTS, "_blank");
  };

  return (
    <>
      <HeaderLogin />
      <div className="wrapper-container-login">
        <div className="container-faq mt-1">
          <h2>
            <i className="fas fa-hands-helping"></i> {t("help")}
          </h2>
          <hr />
          <button
            type="submit"
            className="button-secondary"
            onClick={downloadManual}
          >
            <i className="fas fa-download"></i>
            {t("faqButtonDownload")}
          </button>
          <div className="mt-1">
            <Faq data={dataVideos} />
          </div>
          <div className="mt-1">
            <Faq data={dataQuestions} />
          </div>
        </div>
      </div>
      <FooterLogin />
    </>
  );
};

export default FaqStudents;
